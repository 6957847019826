:root {
  --bg-color: #1d374f;
  --font-family--: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

}

p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li {
  color: white !important;
}

body {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: var(--font-family);

}

#root {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;

}



.empty-space {
  height: 5%;
  background-color: black;
}

/* Define the styles for the scrollbar track */
::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  /* width of the scrollbar */
}

/* Define the styles for the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background: #888;
  /* color of the scrollbar thumb */
  border-radius: 5px;
  /* roundness of the scrollbar thumb */
}

/* Define the styles for the scrollbar track on hover */
::-webkit-scrollbar-track:hover {
  background: #888;
  /* color of the scrollbar track on hover */
}

/* Define the styles for the scrollbar thumb on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
  /* color of the scrollbar thumb on hover */
}

.parent-container .css-11b3ww9-MuiPaper-root-MuiAppBar-root {
  background-color: transparent !important;
  backdrop-filter: blur(30px) !important;
}

.parent-container .css-k008qs {
  background-color: var(--bg-color);
}


/* css-11b3ww9-MuiPaper-root-MuiAppBar-root */
.parent-container .css-hyum1k-MuiToolbar-root {
  background-color: transparent !important;
  height: 70px !important;
  /* background-color: #022f58; */
  /* background-color: #4b769f; */
  /* background-color: white; */
  /* background-color: transparent;
  color: black;
  backdrop-filter: blur(10px); */
}

.parent-container .css-i6s8oy {
  background-color: var(--bg-color) !important;

}

.css-19kzrtu {
  padding: 0px !important;
}

.parent-container {
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  /* background-color: var(--bg-color); */
  /* border: 1px solid red; */
  color: black;
}

.hero-1 {
  padding-top: 80px;
  /* border: 2px solid white; */
  /* height: 550px; */
  width: 100%;
  /* color: white; */

}

.hero-text {
  /* border: 1px solid white; */
  /* height: 300px; */
  font-family: var(--font-family--) !important;
  letter-spacing: 1px !important;
  /* font-weight: 900 !important; */
}


.hero-text h4 {
  color: white !important;
}

.hero-image {
  /* border: 2px solid white; */
  height: 200px;
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30%;
}

.about-image {
  /* border: 2px solid white; */
  /* height: 200px; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30%;
}

.hero-image img {
  border: 1px solid #0072b5;
  width: 112px !important;
  height: 118px;
  border-radius: 80px;
  object-fit: contain;
  background: #728da6b8;
}

.about-image img {
  width: 250px;
  height: 250px;
  border-radius: 50px;
  object-fit: cover;
  opacity: 0;
}


.hero-child1 {
  height: 300px;
  width: 100%;
  /* border: 2px solid white; */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
}

.hero-child1 h1 {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  letter-spacing: 5px;
  font-size: xxx-large;
  background: linear-gradient(to right, rgb(117, 130, 247), rgb(171, 248, 171));
  background-clip: text;
  -webkit-background-clip: text;
  /* -webkit-box-reflect: below 5px linear-gradient(transparent, #0004); */
  -webkit-box-reflect: below -10px linear-gradient(transparent, #ffffff09);

}

.hero-child2 {
  /* height: 150px; */
  width: 100%;
  /* border: 2px solid white; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: space-evenly; */
}

.hero-child2 .hero-icons {
  /* border: 1px solid white; */
  font-size: xx-large;
  /* margin-right: 50px; */
  margin: 10px;
  cursor: pointer;
}

.hero-icon-container {
  /* height: 50%; */
  /* border: 2px solid white; */
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start !important;

}

.hero-scroll-down {
  height: 50px;
  width: 100px;
  border: 1px solid black;
  /* background-color: black; */
  /* background-color: #d5ebff; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 50px;
  align-self: center;
  cursor: pointer;
  transition: 0.2s ease;
  background-color: rgba(245, 245, 245, 0.582);
  /* margin-left: 60%; */
  margin-top: 40px;
}

.hero-scroll-down:hover {
  background-color: white;
  color: black;
  transform: scale(0.9);
}

.container-1 {
  height: 100%;
  width: 100%;
}

/* .container-2 {
  height: 100%;
  width: 100%;
  box-sizing: border-box;

} */



.container-2 {
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: var(--bg-color);
  /* Root background color */
  /* overflow: hidden; */
}

.container-2::before {
  content: '';
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background-image: url('../Images/bg-img.png');
  /* Replace with your image path */
  background-size: contain;
  /* Cover the entire container */
  background-position: 150% 100%;

  /* Center the image */
  background-repeat: no-repeat;
  opacity: 0.15;
  /* Lightly visible image */
  z-index: 0;
  /* Ensure it stays behind the content */
}

.container-2>* {
  position: relative;
  z-index: 1;
  /* Keep content above the background image */
}


.particle {
  position: absolute;
  width: 5px;
  height: 5px;
  background-color: white;
  border-radius: 50%;
  animation: move 10s linear infinite;
  opacity: 0;
  pointer-events: none;
}

@keyframes move {
  0% {
    transform: translateY(0) translateX(0);
    opacity: 1;
  }

  100% {
    transform: translateY(-100vh) translateX(calc(50vw - 100px));
    opacity: 0;
  }

}

@keyframes float {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0);
  }
}



.p-child-container {
  height: 100%;
  width: 100%;
  /* border: 1px solid green; */
  display: flex;
  flex-direction: row;
  box-sizing: border-box;

}

.p-child-container2 {

  /* height: 50%; */
  width: 100%;
  padding: 5%;
  /* border: 1px solid green; */
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  background-color: var(--bg-color);

  /* align-items: center; */
}

.p-child-container2 h3 {
  padding: 50px;
  font-weight: 700;
  font-size: xx-large;
}

.p-child-container2 h5 {
  padding: 10px;
  text-indent: 20px;
  font-weight: 100;
  font-family: var(--font-family--);
  color: white;
}



.container2-child1 {
  height: 100%;
  width: 30%;
  /* border: 1px solid green; */
  display: flex;
  flex-direction: row;
  position: relative;
  background-color: rgb(0, 0, 0);
  z-index: -1;
  /* background-image: url("../Images/dance-bg.jpg"); */
  /* background-size: cover; */
  /* background-position: 100%; */
}

.container2-child2 {
  height: 100%;
  width: 100%;
  /* border: 1px solid green; */
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: rgb(0, 0, 0);
  color: white;
  z-index: -1;
  /* background-image: url("https://img.freepik.com/free-psd/golden-celebration-design-elements_23-2150317957.jpg?t=st=1710831705~exp=1710835305~hmac=8e1d4e5f0534feec1aac9b7fa602d06940ad210d0ff81dad5f1902dbdff656f1&w=740");
  background-position: 80% 80%;
  background-size: cover; */
}

.container2-child2 h1 {
  padding-left: 20%;
}



.About-title {
  /* color: black; */
  text-align: center;
  font-family: serif;
  font-weight: 600;
  margin-top: 5%;
  margin-right: 90%;
  margin-bottom: 5%;
  /* opacity: 0, */
}

.About-content {
  height: 30%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-left: 20%;
}

.About-content2 {
  height: 50%;
  width: 100%;
  /* border: 2px solid red; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 2%;
  padding-left: 3%;
}

.override-content2 {
  background-color: black !important;
}

.bar-1 {
  width: 30%;
  /* border: 2px solid red; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.About-content label {
  width: 50%;
  font-weight: 700;
  font-size: 16px;
  font-family: sans-serif;
  /* Each label takes up half of the container width */
}

.content-values {
  font-size: 16px;

  font-weight: 500;
  text-align: center;
}

/* Adjust label styles as needed */



.particles-container2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.ch-1 {
  height: 100%;
  width: 100%;
  /* border: 1px solid green; */
  background: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ch-2 {
  height: 100%;
  width: 100%;
  /* border: 1px solid green; */
  background: #000;
  display: flex;
  flex-direction: row;
}


.ch-left {
  height: 50%;
  width: 50%;
  text-align: center;
  border: 2px solid white;

}

.ch-right {
  height: 50%;
  width: 50%;
  display: flex;
  justify-content: end;
  align-items: end;
  background-color: transparent;

  margin-top: 150px;
  border: 2px solid white;
}


.title1 {
  position: absolute;
  top: 20%;
  left: 15%;
  color: transparent;
  text-align: center;
  font-size: 3vw;
  z-index: 1;
  font-family: serif;
  background: linear-gradient(to right, rgb(117, 130, 247), rgb(171, 248, 171));
  background-clip: text;
  line-height: 0.7em;
  outline: none;
}

.title2 {
  position: absolute;
  top: 30%;
  left: 15%;
  color: transparent;
  text-align: center;
  font-size: 5vw;
  z-index: 1;
  font-family: serif;
  background: linear-gradient(to right, rgb(117, 130, 247), rgb(171, 248, 171));
  background-clip: text;
  line-height: 0.7em;
  outline: none;
}


.title3 {
  position: absolute;
  top: 29%;
  left: 26%;
  color: rgb(145, 185, 247);
  text-align: center;
  font-size: 6vw;
  z-index: 1;
  font-family: serif;
  background: linear-gradient(to right, rgb(117, 130, 247), rgb(171, 248, 171));
  background-clip: text;
  /* -webkit-box-reflect: below 15px linear-gradient(transparent, #0004); */
  line-height: 0.7em;
  outline: none;
}



.title4 {
  position: absolute;
  top: 50%;
  left: 10%;
  color: rgba(250, 244, 244, 0.644);
  text-align: center;
  font-size: 18px;
  z-index: 1;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-weight: 100;
  /* word-spacing: 0.2px; */
  /* background: linear-gradient(to right, rgb(119, 189, 179), rgb(81, 120, 138)); */
  background-clip: text;
  line-height: 1.5em;
  text-indent: 50px;
  text-align: start;
  outline: none;
  width: 50%;
}

.highlighter {
  background: linear-gradient(to right, rgb(117, 130, 247), rgb(171, 248, 171));
  background-clip: text;
}


.ch-1 p {
  position: absolute;
  top: 85%;
  left: 45%;
  color: white;
  text-align: center;
  font-size: 30px;
  z-index: 1;
  font-weight: 100;
  font-family: serif;
}

.dance {
  height: 400px !important;
  width: 400px !important;
  background-color: transparent;
  position: relative;
  top: 0%;
  left: 0%;
  color: white;
  padding-left: 50px;
  /* padding: 0px 0px 30% 0px; */
  /* text-align: center; */
  align-self: center;
  font: xx-large;
  /* z-index: 1; */
  /* min-width: 900px !important; */
  /* overflow: hidden; */
}

.ship {
  height: 400px !important;
  width: 400px !important;
  background-color: transparent;
  position: relative;
  z-index: 0;
  top: 10%;
  left: 0%;
  color: white;
  padding-left: 50px;
  /* padding: 0px 0px 30% 0px; */
  /* text-align: center; */
  align-self: center;
  font: xx-large;
  /* z-index: 1; */
  /* min-width: 900px !important; */
  /* overflow: hidden; */
}

.earth {
  height: 400px !important;
  width: 400px !important;
  background-color: transparent;
  position: relative;
  z-index: 0;
  top: 70%;
  left: 0%;
  color: white;
  padding-left: 50px;
  /* padding: 0px 0px 30% 0px; */
  /* text-align: center; */
  align-self: center;
  font: xx-large;
  /* z-index: 1; */
  /* min-width: 900px !important; */
  /* overflow: hidden; */
}

.RSPBprogressBar {
  width: 50% !important;
  height: 15% !important;
  background-color: transparent !important;
  border: 1px solid white;
}


.RSPBprogression {
  background: linear-gradient(to left, rgb(2, 150, 173), rgb(210 47 240)) !important;
  /* background-color: pink !important; */
}

.p-child-container3 {
  /* padding-top: 10%; */
  /* height: 180%; */
  width: 100%;
  /* border: 1px solid green; */
  /* z-index: 999; */
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: center; */
  /* background-color: rgb(0, 0, 0); */
}

.p-child-container4 {
  /* padding-top: 10%; */
  /* height: 50%; */
  width: 100%;
  /* border: 1px solid green; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--bg-color);
}

.p-child-container5 {
  /* padding-top: 10%; */
  height: 100%;
  width: 100%;
  /* border: 1px solid green; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(0, 0, 0);
}


.container3-child1 {
  height: 10%;
  width: 100%;
  /* background-color: grey; */
  /* border: 1px solid white; */
}

.container4-child1 {
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-color);
  /* background-color: grey; */
  /* border: 1px solid white; */
}

.container5-child1 {
  height: 10%;
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  text-align: center;
}

.resume-title-head {
  width: 100%;
  color: white;
  padding-left: 100px;
  background-color: var(--bg-color);
  font-weight: 600;
  font-family: var(--font-family--);
  display: flex;
  align-items: start !important;
  /* justify-content: center; */
}

.resume-title-head h3 {
  background-color: var(--bg-color);
  text-align: start !important;
  color: white;
  font-family: var(--font-family--);
  font-weight: 900;
  font-size: xx-large;
}

.resume-content {
  background-color: var(--bg-color);
  width: 100%;
}

.resume-content {}

.service-title {
  width: 100%;
  height: 100%;
  color: white;

  font-family: serif;
  font-weight: 600;
  /* border: 2px solid white; */
  /* padding-left: 20%; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-title-head {
  width: 100%;
  height: 100%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}


.portfolio-title {
  width: 100%;
  height: 100%;
  color: white;

  /* font-family: serif; */
  font-weight: 600;
  /* border: 2px solid white; */
  /* padding-left: 20%; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.container3-child2 {
  height: 70%;
  /* border: 1px solid white; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.container4-child2 {
  height: 70%;
  background-color: var(--bg-color);
  /* border: 1px solid white; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container4-child2-languages {
  /* height: 70%; */
  background-color: var(--bg-color);
  width: 100%;
  /* border: 1px solid white; */
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}

.p-child-container4-languages {
  width: 100%;
  /* height: 70%; */
  background-color: var(--bg-color);
  /* border: 1px solid white; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container4-child2 h4 {
  font-size: larger;
  padding: 40px;
  line-height: 30px;
}

.container4-child2 a {
  padding-left: 10px;
}

.container5-child2 {
  height: 80%;
  width: 100%;
  background-color: white;
  /* border: 1px solid white; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.c5-children1 {
  height: 100%;
  width: 40%;
  /* border: 1px solid white; */
}


.card-component {
  /* border: 1px solid white; */
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  /* display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); */
  /* Adjust as needed */
}




.css-1xkonbu-MuiPaper-root-MuiCard-root {
  width: 20% !important;
  height: 80% !important;

}

.css-i6s8oy {
  background-color: black !important;
}


.card-component .bar {
  transition: transform 0.3s, opacity 0.3s !important;
}

.card-component .bar-hover:hover {
  transform: scale(1.05) !important;
}

.custom-shape-divider-bottom-1710834622 {
  position: absolute;
  bottom: 45%;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-bottom-1710834622 svg {
  position: relative;
  display: block;
  width: calc(145% + 1.3px);
  height: 70px;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1710834622 .shape-fill {
  fill: #000000;
}



#portfolio {
  width: 100%;
  height: 100%;
}






.form {
  display: flex;
  flex-direction: column;
  align-self: center;
  font-family: inherit;
  gap: 10px;
  padding-inline: 2em;
  padding-bottom: 0.4em;
  background-color: #171717;
  /* background-color: #0a192f; */
  border-radius: 20px;
}

.form-heading {
  text-align: center;
  margin: 2em;
  color: #64ffda;
  font-size: 1.2em;
  background-color: transparent;
  align-self: center;
}

.form-field {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  border-radius: 10px;
  padding: 0.6em;
  border: none;
  outline: none;
  color: white;
  background-color: #f0f8ff1f;
  /* background-color: #171717; */
  /* box-shadow: inset 2px 5px 10px rgb(5, 5, 5); */
}

.input-field {
  background: none;
  border: none;
  outline: none;
  width: 100%;
  color: #ccd6f6;
  padding-inline: 1em;
}

.sendMessage-btn {
  cursor: pointer;
  margin-bottom: 3em;
  padding: 1em;
  border-radius: 10px;
  border: none;
  outline: none;
  background-color: transparent;
  color: #64ffda;
  font-weight: bold;
  outline: 1px solid #64ffda;
  transition: all ease-in-out 0.3s;
}

.sendMessage-btn:hover {
  transition: all ease-in-out 0.3s;
  /* background-color: #64ffda; */
  background: linear-gradient(to left, rgb(152, 228, 240), rgb(242, 168, 255)) !important;

  color: #000;
  cursor: pointer;
  /* box-shadow: inset 2px 5px 10px rgb(5, 5, 5); */
}

.form-card1 {
  background-image: linear-gradient(163deg, #a6e9c1 0%, #f4b8f5 100%);
  border-radius: 10px;
  transition: all 0.3s;
  width: 40%;
  z-index: 0;
  /* height: 200px; */
}

.form-card1:hover {
  /* box-shadow: 0px 0px 30px 1px rgba(100, 255, 218, 0.3); */
}

.form-card2 {
  border-radius: 0;
  transition: all 0.2s;
}

.form-card2:hover {
  /* transform: scale(0.98); */
  border-radius: 20px;
}

.footer {
  /* height: 40%; */
  width: 100%;
  /* background-color: black; */
  background-color: #214063;
  /* border: 2px solid white; */
}

.footer-content {
  padding: 50px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.footer-contact label {
  color: white;
  /* font-size: larger;
  font-weight: 300; */
}

.footer-contact h3 {
  font-size: x-large;
  color: white;
  padding: 20px;
}

.footer-contact {
  height: 100%;
  width: 50%;
  /* border: 1px solid white; */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
}

.footer-links {
  /* height: 100%; */
  width: 50%;
  /* border: 1px solid white; */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
}

.footer-links li {
  color: white;
  font-size: large;
  /* font-weight: 300; */
}

.footer-links h3 {
  font-size: x-large;
  color: white;
  padding: 20px;
}

.contact-info {
  padding-left: 20px;
}

.experience-slider {
  padding: 20px;
  background-color: var(--bg-color);
}

.experience-slider .swiper-backface-hidden .swiper-slide {
  background-color: #214063;
  height: auto !important;
}

.experience-slider .swiper-button-next,
.swiper-rtl .swiper-button-prev {
  color: white !important;
}

.experience-slider .swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  color: white !important;
}

.experience-slider .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  margin-top: 25px;
  background-color: white !important;
}




.text-justify {
  text-indent: 0px !important;
}




@media screen and (min-width: 0px) and (max-width: 576px) {

  .hero-1 .dialogue {
    font-size: 16px !important;
  }

  .container4-child2-languages {
    /* height: 70%; */
    background-color: var(--bg-color);
    width: 100%;
    /* border: 1px solid white; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .resume-title-head {
    padding-top: 20px;
  }


  .container-2 {
    position: relative;
    width: 100%;
    height: 100vh;
    /* background-color: var(--bg-color); */
    /* Root background color */
    /* overflow: hidden; */
  }

  .container-2::before {
    content: '';
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    /* background-image: url('../Images/bg-img.png'); */
    /* Replace with your image path */
    background-size: cover;
    /* Cover the entire container */
    background-position: 75% 0%;

    /* Center the image */
    background-repeat: no-repeat;
    opacity: 0.17;
    /* Lightly visible image */
    z-index: 0;
    /* Ensure it stays behind the content */
  }

  .container-2>* {
    position: relative;
    z-index: 1;
    /* Keep content above the background image */
  }


  .hero-text {
    font-size: xx-large !important;
  }

  .p-child-container3 {
    /* height: 280%; */
    height: auto;
  }


  .hero-child1 h1 {
    font-size: xxx-large;
    text-align: center;
    /* -webkit-box-reflect: below -5px linear-gradient(transparent, #30303021); */

  }

  .p-child-container2 h5 {
    font-size: large;
  }

  .footer-contact label {
    font-size: large;
  }

  .footer-content {
    flex-direction: column;
  }

  .footer-contact,
  .footer-links {
    width: 100%;
    height: 50%;
    align-items: start;
    /* padding: 20px; */
  }


}

@media screen and (min-width: 576px) and (max-width: 768px) {


  .container-2 {
    position: relative;
    width: 100%;
    height: 100vh;
    /* background-color: var(--bg-color); */
    /* Root background color */
    /* overflow: hidden; */
  }

  .container-2::before {
    content: '';
    position: fixed;
    top: 30%;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../Images/bg-img.png');
    /* Replace with your image path */
    background-size: contain;
    /* Cover the entire container */
    background-position: 0% 0%;

    /* Center the image */
    background-repeat: no-repeat;
    opacity: 0.1;
    /* Lightly visible image */
    z-index: 0;
    /* Ensure it stays behind the content */
  }

  .container-2>* {
    position: relative;
    z-index: 1;
    /* Keep content above the background image */
  }

  .p-child-container3 {
    height: auto;
  }


}

@media screen and (min-width: 768px) and (max-width: 992px) {

  .container-2 {
    position: relative;
    width: 100%;
    height: 100vh;
    /* background-color: var(--bg-color); */
    /* Root background color */
    /* overflow: hidden; */
  }

  .container-2::before {
    content: '';
    position: fixed;
    top: 30%;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../Images/bg-img.png');
    /* Replace with your image path */
    background-size: contain;
    /* Cover the entire container */
    background-position: 0% 0%;

    /* Center the image */
    background-repeat: no-repeat;
    opacity: 0.1;
    /* Lightly visible image */
    z-index: 0;
    /* Ensure it stays behind the content */
  }

  .container-2>* {
    position: relative;
    z-index: 1;
    /* Keep content above the background image */
  }


  .p-child-container3 {
    height: auto;
  }


}